import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footerMain">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-lg-0 mb-3">
            <Link to="/">
              <img src="/assets/imgs/white-logo.svg" alt="" />
            </Link>
            <p>
              An online marketplace for buying and selling of artworks, ensuring
              maximum valuation through online closed bidding.
            </p>
            <h4>Follow us</h4>
            <div className="d-flex gap-3 mt-3">
              <div className="iconMain">
                <a
                  href="https://www.instagram.com/bolle_ig?igsh=cnExb2JtdW45M3d4&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/imgs/instagram_icon.svg" alt="" />
                </a>
              </div>
              <div className="iconMain">
                <a
                  href="https://www.facebook.com/share/yVxsKQRGU88qyexf/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/imgs/facebook_icon.svg" alt="" />
                </a>
              </div>
              <div className="iconMain">
                <a
                  href="https://linkedin.com/company/boll-e"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/imgs/linkedin_icon.svg" alt="" />
                </a>
              </div>
              <div className="iconMain">
                <a
                  href="https://x.com/bolle_twt?s=11"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/imgs/twitter_icon.svg"
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                </a>
              </div>
              <div className="iconMain">
                <a
                  href="https://www.tiktok.com/@bolle_tiktok?_t=8pSzb3fLFdQ&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/imgs/tiktok_icon.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 mb-lg-0 mb-3"></div>
          <div className="col-lg-2 col-md-4 mb-lg-0 mb-3"></div>
          <div className="col-lg-4 col-md-6 mb-lg-0 mb-3 text-white">
            Phone <br />
            +92-339-4011462 <br />
            +92-331-4190517 <br /> <br />
            Email address <br />
            info@boll-e.com <br /> <br />
            Address <br />
            495 E-1, Wapda Town, Lahore. <br />
          </div>
        </div>
        <div className="my-0 py-2">
          <hr style={{ border: "1px solid #3F3F3F" }} />
        </div>
        <div
          style={{ background: "none" }}
          className="copyRightSection pt-0 position-relative d-flex align-items-center justify-content-center gap-sm-3 gap-2 flex-md-row flex-column"
        >
          <p className="mt-0 text-center">
            Copyright {new Date().getFullYear()} by Boll-e. All Rights Reserved
          </p>
          <span className="d-sm-flex d-none"></span>
          <a
            href={process.env.REACT_APP_TC_URL}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            <p className="mt-0 text-center">Terms & condition</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
